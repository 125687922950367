.btn {
  @apply bg-blue-500 text-white p-2 rounded w-full cursor-pointer hover:bg-blue-600 transition-colors;
}

.alert-danger {
  @apply bg-red-100 text-red-900 p-2 rounded dark:bg-red-900 dark:text-red-100;
}

.alert-info {
  @apply bg-blue-100 text-blue-900 p-2 rounded dark:bg-blue-900 dark:text-blue-100;
}

.form-group {
  @apply py-2;
}

.input-group {
  @apply flex;
}

.input-group-prepend {
  @apply w-12 flex bg-gray-200 rounded-l border border-gray-300 dark:bg-gray-700 dark:border-black dark:text-white;
}

.form-control {
  @apply py-2 px-4 rounded-r border border-l-0 border-gray-300 w-full dark:bg-gray-600 dark:border-black dark:text-white;
}

.input-group-text {
  @apply m-auto;
}

footer > div {
  margin: 0 auto;
}

/* This is a hack because browsers do not follow the CSS standard with respect to specifity of display rules
see: https://stackoverflow.com/questions/68206422/tailwinds-darkhidden-darkblock-wont-override-its-own-hidden-selector*/
@media (prefers-color-scheme: dark) {
  .dark\:block {
    display: block;
  }
}
